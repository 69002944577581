import "./WorkCardStylesNew.css";

import React from "react";
import { NavLink } from "react-router-dom";

function WorkCard(props) {
	return (
		<NavLink to={props.view} className="tile">
			<div class="tile-content">
				<div class="image-wrapper">
					<div>
						<img src={props.imgsrc} alt="" />
					</div>
				</div>
				<h2 class="tile-title">{props.title}</h2>
				<h4 class="tile-subtitle">{props.text}</h4>
			</div>
		</NavLink>

		/*<NavLink to={props.view} className="project-card">
			<img src={props.imgsrc} alt="" />
			<div className="cropped">
				<h1 className="project-title">{props.title}</h1>
				<div className="pro-details">
					<p>{props.text}</p>
				</div>
			</div>
		</NavLink>*/
	);
}

export default WorkCard;
