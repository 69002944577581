import React from "react";
import "../components/ArticleStyles.css";
import Navbar from "../components/Navbar";
import img0 from "../assets/ComplexBiology/OnlyMultiplex.png";
import img1 from "../assets/ComplexBiology/literature-workflow.jpg";
import img2 from "../assets/ComplexBiology/Assaybuilder.png";
import img3 from "../assets/ComplexBiology/Assaybuilder2.png";
import img4 from "../assets/ComplexBiology/Assaybuilder3.png";
import img5 from "../assets/ComplexBiology/Assaybuilder4.png";
import img6 from "../assets/ComplexBiology/Positivity-Direct manipulation.png";
import img7 from "../assets/ComplexBiology/Cell Tindering.png";
import img8 from "../assets/ComplexBiology/Thresholding.png";
import img9 from "../assets/ComplexBiology/author1.png";
import img10 from "../assets/ComplexBiology/author2.png";
import img11 from "../assets/ComplexBiology/author3.png";
import img12 from "../assets/ComplexBiology/author4.png";
import img13 from "../assets/ComplexBiology/Workflow Test 1.png";
import img14 from "../assets/ComplexBiology/Workflow Test 2.png";
import img15 from "../assets/ComplexBiology/Workflow Test 3.png";
import img16 from "../assets/ComplexBiology/Workflow Test 4.png";
import img17 from "../assets/ComplexBiology/Workflow Test 5.png";
import img18 from "../assets/ComplexBiology/Workflow Test 6.png";
import img19 from "../assets/ComplexBiology/Workflow Test 7.png";
import img20 from "../assets/ComplexBiology/Workflow Test 8.png";
import img21 from "../assets/ComplexBiology/Workflow Test 9.png";

import img22 from "../assets/ComplexBiology/Workflow.png";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ShortDescriptionCB from "../components/ShortDescriptionCB";
import Footer from "../components/Footer";

const ComplexBiology = () => {
	return (
		<>
			<Navbar />

			<div className="article-container">
				<div className="content-container">
					<h1>Understanding complex spatial biology</h1>
					<p>
						Augmenting the visualization of the complex spatial biology of the
						tissue microenvironment.
					</p>
					<div className="article-img">
						<img alt="" src={img0} width="100%" />
					</div>
					<ShortDescriptionCB />

					<div className="content-details">
						<h2 className="content-title">UX Activities</h2>

						<ul>
							<li>
								<span>Analysing existing solution</span>
							</li>
							<li>
								<span>Interviewing external partners to map expectations</span>
							</li>

							<li>
								<span>
									Interviewing users from different user segments located in the
									EU and US.
								</span>
							</li>
							<li>
								<span>Designing UI</span>
							</li>
							<li>
								<span>
									Testing and co-creating prototypes/workflows with users
								</span>
							</li>
							<li>
								<span>Formative usability testing</span>
							</li>
						</ul>
					</div>
					<div className="content-details">
						<h2 className="content-title">Discovery</h2>
						<h3>Context</h3>
						<p>
							A cell can be classified by the biomarkers expressed in, on, and
							around it. This classification is normally described as the
							phenotype of the cell.
						</p>

						<p>
							Novel imaging modalities can image 40 or more protein biomarkers
							in one scan at single-cell resolution. This is achieved by
							precisely directing a laser beam (focused at 1 μm) at a region of
							tissue treated with a solution of rare earth metals. When the
							laser hits the tissue, the tissue gets ablated. All material from
							the ablation process is analyzed in a mass spectrometer. The
							analysis reveals which rare-earth metals were ablated and since we
							know which protein biomarkers the metal is bound to, it is
							possible to create a density map of each biomarker. Stacking the
							density maps on top of each other produces an image of the tissue
							with as many channels as there are biomarkers. In comparison, a
							regular RGB image has only three channels.
						</p>
						<p>
							These images contain information about 40 or more protein
							biomarkers expressed in the tissue. H&E (the most common image
							type in a lab) contains information about one protein biomarker.
							Having more information about the tissue and the cells in the
							tissue opens the possibility of developing new drugs and
							treatments for various diseases. To make sense of the information,
							it is essential to utilize image analysis software since the sheer
							amount of data makes it impossible for the human to comprehend.
						</p>
						<h3>Problem</h3>
						<p>
							From competitor analysis, inventory analysis, and extensive user
							research (interviews and co-creation workshops), we discovered the
							following general problems for the majority of the users:
						</p>
						<ul>
							<li>
								<span>
									Working with confidence - Extracting the information manually
									from the images is biased and prone to inter and intraobserver
									variance. Some even consider it impossible to do manually.
								</span>
							</li>
							<li>
								<span>
									Heavily manual workflow - The current workflow is made up of
									several smaller software components that do not integrate thus
									introducing many manual steps from scan to analyzed image.
								</span>
							</li>

							<li>
								<span>
									Quality control - The images must be quality controlled by
									specialized pathologists who already are overloaded with work
								</span>
							</li>
							<li>
								<span>
									Reuse algorithms - It takes a long time to create a robust
									image analysis algorithm so it becomes frustrating when you
									cannot reuse the algorithms on the subsequent dataset.
								</span>
							</li>
							<li>
								<span>
									Advanced coding knowledge required - To process the images,
									laboratories have employed image analysis scientists to write
									extensive scripts in Python, R, etc. This limits the market
									reach since only a few laboratories can afford such a
									workflow.
								</span>
							</li>
						</ul>
						<h3>Hypothesis</h3>
						<p>
							Through observation studies of our users, we learned that users
							want to understand the underlying logic of image analysis
							algorithms even if it causes additional manual tasks. From these
							experiences, we defined a hypothesis to guide the discovery work.
						</p>
					</div>
					<div className="quote">
						<p>
							We hypothesized that user needs to have the option to control the
							rule set for classification when classifying cells into
							phenotypes.
						</p>
					</div>
					<div className="content-details">
						<h2 className="content-title">Develop</h2>
						<h3>Ideation</h3>
						<p>
							We defined a general workflow based on user research and
							literature that all users must go through to extract any
							information from the images:
						</p>

						<ul>
							<li>
								<span>
									Set up an assay (the group of biomarkers treated with rare
									earth metals before image capture) you want to work with.
								</span>
							</li>
							<li>
								<span>QC images</span>
							</li>

							<li>
								<span>
									Compartmentalise tissue (into tumour, stroma, etc.) - Optional
								</span>
							</li>
							<li>
								<span>Identify nuclei in tissue</span>
							</li>
							<li>
								<span>
									Define when a nucleus is positive or negative for a specific
									biomarker
								</span>
							</li>
							<li>
								<span>QC results</span>
							</li>
							<li>
								<span>Export results</span>
							</li>
						</ul>
					</div>
					<div className="article-img">
						<Zoom>
							<img
								alt="Workflow from literature studies"
								src={img1}
								width="100%"
							/>
						</Zoom>
						<p>Workflow from the literature studies</p>
					</div>

					<div className="content-details">
						<h3>Iterations</h3>
						<div className="light">
							<p>Set up an assay and QC of images</p>
						</div>

						<p>
							We wanted to bridge the gap between computer science and biology
							by allowing the user to set up the assay he/she would be working
							on. This would allow us to create an interface with domain
							terminology based on biological rules. Potentially, it would allow
							the user to ask biologically relevant questions without having to
							first configure the software.
						</p>
						<p>
							Our first approach was to create an assay builder where users
							could select relevant biomarkers. We looked at the components
							already available and realized that nothing really could be used.
							Instead, we built a completely new component. Through usability
							testing, we realized that users only benefitted minimally from
							having this solution and the component imposed a great amount of
							development work which eventually made us scrap the solution.
						</p>
						<p>
							Based on the feedback from customers we realized that steps 1 and
							2 could be solved with one solution where the user would define
							the assay and QC the images at the same time. The new solution
							would not bridge the gap between computer science and biology the
							same way as previously but we also learned that every scientist
							with a biology background would have his/her way of doing and
							understanding things.
						</p>
					</div>

					<div className="image-grid">
						<div className="article-img2">
							<Zoom>
								<img
									alt="USER JOURNEYS FOR CURRENT FILE MANAGEMENT - Sequence Model"
									src={img2}
									width="100%"
								/>
							</Zoom>

							<Zoom>
								<img
									alt="USER JOURNEYS FOR CURRENT FILE MANAGEMENT - Sequence Model"
									src={img3}
									width="52%"
								/>
							</Zoom>
							<Zoom>
								<img
									alt="USER JOURNEYS FOR CURRENT FILE MANAGEMENT - Sequence Model"
									src={img4}
									width="100%"
								/>
							</Zoom>

							<Zoom>
								<img
									alt="USER JOURNEYS FOR CURRENT FILE MANAGEMENT - Sequence Model"
									src={img5}
									width="21%"
								/>
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>
							Development of concepts - Concepts 1 and 2 were scrapped and
							repurposed into concept 3 which was further refined into concept
							4.{" "}
						</p>
					</div>

					<div className="content-details">
						<div className="light">
							<p>Define Positivity For A Cell</p>
						</div>
						<p>
							We defined four different principles for seting the positivity of
							a cell where three of which give the user control over the
							classification of phenotypes:
						</p>
						<ul>
							<li>
								<span>Gaussian mixture model (Automatic)</span>
							</li>
							<li>
								<span>Direct manipulation</span>
							</li>

							<li>
								<span>Cell tindering</span>
							</li>
							<li>
								<span>Thresholding</span>
							</li>
						</ul>
						<p>
							We realized that users want assistance to classify the phenotypes
							but mistrust when the system does it automatically without them
							being able to assess the rules for classification. We also
							realized that any rules based on reviewing cells one by one would
							increase the workload on the users quite significantly. We chose
							to go with an approach where the users can set rules based on an
							overview of many cells.
						</p>
					</div>

					<div className="image-grid">
						<div className="article-img3">
							<Zoom>
								<img alt="Direct Manipulation" src={img6} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Cell by Cell assessment" src={img7} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Thresholding" src={img8} width="100%" />
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>
							Direct manipulation, assessing cell-by-cell, and thresholding.{" "}
						</p>
					</div>

					<div className="content-details">
						<p>
							Direct manipulation was, at the time, industry standard, and the
							user would normally provide around 30 examples of a positive and
							negative cell for a single biomarker. This amounts to a minimum of
							1200 clicks for an image with 40 biomarkers.
						</p>

						<p>
							Cell-by-cell assessment would decrease the number of clicks to
							around 10 per biomarker. The algorithm would only ask the user to
							define positivity of cells that it struggles to classify (active
							learning). The idea was also scrapped since 400 clicks for an
							image with 40 biomarkers were considered excessive.
						</p>

						<p>
							Combined thresholding and cell-by-cell assessment would allow the
							user to set general rules and then later fine-tune the algorithms.
							Potentially it would also require 40 clicks to get to a result.
							Based on user and expert interviews, we learned that users would
							rather fine-tune the algorithm later while QCing the results. We
							scrapped that cell-by-cell assessment and kept the threshold. This
							also proved the previously defined hypothesis. 40 clicks might
							still seem excessive but compared to our competitors it is a 96%
							improvement.
						</p>
						<div className="light">
							<p>Workflow</p>
						</div>

						<p>
							Once we had the prototypes of the separate pieces in the workflow,
							we started thinking about how they would come together in a
							coherent workflow.
						</p>
						<p>
							Our initial approach was to integrate it into an existing workflow
							for building algorithms. We went through several design iterations
							that ultimately did not satisfy the needs of the users.
						</p>
					</div>

					<div className="image-grid">
						<div className="article-img2">
							<Zoom>
								<img alt="Workflow concept 1" src={img9} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow concept 1" src={img10} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow concept 1" src={img11} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow concept 1" src={img12} width="100%" />
							</Zoom>
						</div>
					</div>

					<div className="article-img">
						<p>First workflow concept utilizing an exsiting component</p>
					</div>
					<div className="content-details">
						<p>
							Based on the feedback from the first approach, I created a concept
							of a workflow engine that could initialize the relevant components
							when needed.
						</p>
					</div>
					<div className="image-grid">
						<div className="article-img3">
							<Zoom>
								<img alt="Workflow Test 1" src={img13} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 2" src={img14} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 3" src={img15} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 4" src={img16} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 5" src={img17} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 6" src={img18} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 7" src={img19} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 8" src={img20} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Workflow Test 9" src={img21} width="100%" />
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>Images of all the workflow prototypes 1-9</p>
					</div>
					<div className="content-details">
						<p>
							I conducted several formative usability tests to see which one was
							the most effective. We selected one of the concepts based on the
							test results. We further refined it since some users were confused
							about the meaning behind the colors.
						</p>
					</div>
					<div className="article-img">
						<Zoom>
							<img alt="Final Workflow" src={img22} width="20%" />
						</Zoom>
						<p>Final Workflow</p>
					</div>
					<div className="content-details">
						<h2 className="content-title">Deliver</h2>

						<p>What the project delivered:</p>
						<ul>
							<li>
								<span>
									Increased sales – The solution opens up a new customer segment{" "}
								</span>
							</li>
							<li>
								<span>
									Improved workflow for core users – User research confirmed
									that users can now test hypotheses with the new solution that
									they previously thought impossible to test.
								</span>
							</li>

							<li>
								<span>
									Radically better than the competitors - Users can get
									meaningful results 96% faster than any competing product.
								</span>
							</li>
							<li>
								<span>
									New components impact other customer segments - The components
									were designed to also work for other customer segments.
								</span>
							</li>
							<li>
								<span>
									Improved stability of platform – Reduced engineering
									maintenance costs by removing legacy products from the code
									base.
								</span>
							</li>
						</ul>
					</div>
					<div className="content-details">
						<h2 className="content-title">Key Learnings</h2>

						<p>
							Design by committee never works - During the project, our team had
							to navigate the expectations of internal and external
							stakeholders. Listening to the stakeholder expectations rather
							than the user opinions slowed down the project significantly. Once
							we removed all “gut feelings” and irrelevant constraints, the team
							moved much faster.{" "}
						</p>
						<p>
							Prototypes as boundary objects - Users are generally biased and
							will give answers to your questions concerning his/her current
							workflow. Prototypes are excellent when explaining radically
							different concepts since users otherwise cannot imagine what you
							are getting at. If a concept is very futuristic it can help to
							make a placeholder concept or vision just to have something to
							relate to.
						</p>
						<p>
							Avoid scope creep - The project was delayed several times since
							business needs were changed due to rapid developments in the
							market. The team had to reset every time the business needs were
							changed thereby changing the scope.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ComplexBiology;
