import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyCNBElHMa8AzQdzVD-CZdo-VLGQ0XHEgZs",
	authDomain: "hello-world-7c5a9.firebaseapp.com",
	databaseURL: "https://hello-world-7c5a9.firebaseio.com",
	projectId: "hello-world-7c5a9",
	storageBucket: "hello-world-7c5a9.appspot.com",
	messagingSenderId: "203653477199",
	appId: "1:203653477199:web:854be34814557683770b3a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth };
