import React from "react";
import Navbar from "../components/Navbar";
import HeroImg from "../components/HeroImg";

const Home = () => {
	return (
		<>
			<Navbar />
			<HeroImg />
		</>
	);
};

export default Home;
