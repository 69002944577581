import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./FormStyles.css";
import {
	signInWithEmailAndPassword,
	sendEmailVerification,
} from "firebase/auth";
import { auth } from "./firebase";
import { useAuthValue } from "./AuthContext";

function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const { setTimeActive } = useAuthValue();
	const history = useHistory();

	const login = (e) => {
		e.preventDefault();
		signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				if (!auth.currentUser.emailVerified) {
					sendEmailVerification(auth.currentUser)
						.then(() => {
							setTimeActive(true);
							history.push("/verify-email");
						})
						.catch((err) => alert(err.message));
				} else {
					history.push("/projects");
				}
			})
			.catch((err) => setError(err.message));
	};

	return (
		<div className="center">
			<div className="auth">
				<form onSubmit={login} name="login_form">
					<h1 className="heading">Log in</h1>
					{error && <div className="auth__error">{error}</div>}
					<label>Email</label>
					<input
						type="email"
						value={email}
						required
						placeholder="Enter your email"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label>Password</label>
					<input
						type="password"
						value={password}
						required
						placeholder="Enter your password"
						onChange={(e) => setPassword(e.target.value)}
					/>

					<button type="submit" className="btn">
						Login
					</button>
					<div className="text-info">
						<p>
							Don't have an account?
							<br />
							<Link to="/register">Click here to signup</Link>
						</p>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Login;
