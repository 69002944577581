import "./FooterStyles.css";

import { FaMailBulk, FaLinkedin } from "react-icons/fa";

import React from "react";
import { ExternalLink } from "react-external-link";

const Footer = () => {
	return (
		<div className="footer">
			<div className="footer-container">
				<div className="top">
					<h2>Contact Me</h2>
				</div>
				<div className="bottom">
					<ExternalLink
						href="https://www.linkedin.com/in/rasmus-norre-sorensen"
						className="social"
					>
						<FaLinkedin
							size={20}
							style={{ color: "#fff", marginRight: "1rem" }}
						/>
						<p>Linkedin</p>
					</ExternalLink>

					<ExternalLink
						href="mailto:innovat1@innovationblog.dk"
						className="email"
					>
						<FaMailBulk
							size={20}
							style={{ color: "#fff", marginRight: "1rem" }}
						/>
						<p>Email</p>
					</ExternalLink>
				</div>
			</div>
		</div>
	);
};

export default Footer;
