import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { AuthProvider } from "./AuthContext";
import MyProfile from "./routes/MyProfile";

import VerifyEmail from "./VerifyEmail";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import Projects from "./routes/Projects";
import Home from "./routes/Home";
import Method from "./routes/Method";
import FileManager from "./routes/FileManager";
import ComplexBiology from "./routes/ComplexBiology";
import QualityAssessment from "./routes/QualityAssessment";
import ScrollToTop from "./components/ScrollToTop";

function App() {
	const [currentUser, setCurrentUser] = useState(null);
	const [timeActive, setTimeActive] = useState(false);

	//Disable right-click
	document.oncontextmenu = new Function("return false");

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			setCurrentUser(user);
		});
	}, []);
	return (
		<Router>
			<ScrollToTop />
			<AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
				<Switch>
					<PrivateRoute exact path="/profile" component={MyProfile} />
					<PrivateRoute exact path="/projects" component={Projects} />
					{/*<PrivateRoute exact path="/method" component={Method} />*/}
					<PrivateRoute exact path="/filemanager" component={FileManager} />
					<PrivateRoute
						exact
						path="/complexbiology"
						component={ComplexBiology}
					/>
					<PrivateRoute
						exact
						path="/qualityassessment"
						component={QualityAssessment}
					/>
					<Route exact path="/" component={Home} />
					<Route exact path="/login" component={Login} />
					{/*<Route exact path="/register" component={Register} />*/}
					<Route exact path="/verify-email" component={VerifyEmail} />
				</Switch>
			</AuthProvider>
		</Router>
	);
}

export default App;
