import React from "react";
import "../components/ArticleStyles.css";
import Navbar from "../components/Navbar";
import img0 from "../assets/Quality/OnlyQuality.png";
import img1 from "../assets/Quality/Apple Coverflow.png";
import img2 from "../assets/Quality/Concept1.png";
import img3 from "../assets/Quality/Homepage.png";
import img4 from "../assets/Quality/Inbox style.png";
import img5 from "../assets/Quality/Only Side menu.png";
import img6 from "../assets/Quality/MS style.png";
import img7 from "../assets/Quality/hoverbuttons.gif";
import img8 from "../assets/Quality/Dashboard.png";
import img9 from "../assets/Quality/plotconfig.gif";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ShortDescriptionQA from "../components/ShortDescriptionQA";
import Footer from "../components/Footer";

const QualityAssessment = () => {
	return (
		<>
			<Navbar />

			<div className="article-container">
				<div className="content-container">
					<h1>Quality assessment of diagnostic markers</h1>
					<p>
						Providing correct treatment for cancer patients through better
						quality assessment of diagnostic biomarkers.
					</p>
					<div className="article-img">
						<img alt="" src={img0} width="100%" />
					</div>
					<ShortDescriptionQA />

					<div className="content-details">
						<h2 className="content-title">UX Activities</h2>

						<ul>
							<li>
								<span>
									Interviewing internal stakeholders to map expectations
								</span>
							</li>
							<li>
								<span>
									Interviewing users from different user segments located in the
									EU, UK and US.{" "}
								</span>
							</li>

							<li>
								<span>Conceptualization and visualization of workflows</span>
							</li>
							<li>
								<span>Designing UI</span>
							</li>
							<li>
								<span>
									Testing and co-creating prototypes/workflows with users
								</span>
							</li>
							<li>
								<span>Providing input to selection of framework </span>
							</li>
							<li>
								<span>Creating style files for code base</span>
							</li>
							<li>
								<span>Usability testing of the final solution</span>
							</li>
						</ul>
					</div>
					<div className="content-details">
						<h2 className="content-title">Discovery</h2>
						<h3>Context</h3>
						<p>
							Pathologists must review expressions of certain biomarkers in the
							tumor tissue to assess if cancer patients are eligible for certain
							treatments. To make the biomarker expression visible, biopharma
							companies provide laboratories with specialized stains which bind
							to the protein biomarkers relevant for the assessment. As an
							example, laboratories use immunohistochemical stains to determine
							if cancer patients are eligible for immunotherapy. Biopharma
							companies produce thorough guidelines to be used by the laboratory
							staff and pathologists when producing and assessing a stained
							tissue sample. Regardless of guidelines and controls, it is a
							challenge to standardize this process. Sources of error accumulate
							across the entire workflow affecting the appearance of the stained
							tissue sample. A study found that up to 30% of tissue glass slides
							are incorrectly stained, which potentially reduces diagnostic and
							prognostic performance.
						</p>

						<h3>Problem</h3>

						<ul>
							<li>
								<span>
									Treatments require a high degree of standardization - Eligible
									Patients must receive certain treatments while ineligible
									patients must not receive them since they can be harmful to
									the patients. Pathologists are only as capable as their tools
									allow them so if the standardization is low it becomes almost
									impossible for the pathologist to know if he/she is right
									about an assessment.
								</span>
							</li>
							<li>
								<span>
									High quality - It requires manual labor to ensure the quality
									of all stain tissue. Labs are already under pressure to cut
									costs so they cannot afford to hire more people.
								</span>
							</li>

							<li>
								<span>
									Inter- and intra-observer variance - It has been proven that
									humans are bad at determining contrast ratios. Even trained
									pathologists change their assessment of the same tissue sample
									depending on the time of the day.
								</span>
							</li>
						</ul>
						<h3>Business need</h3>
						<p>
							The business required us to make a solution with as few
							integrations as possible since any system integration would
							require additional time and money which were not allocated to the
							project. This meant that we could not use any of the client-based
							software already developed for other projects.
						</p>
						<h3>Hypothesis</h3>
						<p>
							We knew that lab staff would have little or no time to operate the
							solution we were about to create.
						</p>
					</div>
					<div className="quote">
						<p>
							We hypothesized that the solution would only be considered useful
							if it required as little interaction as possible.
						</p>
					</div>
					<div className="content-details">
						<h2 className="content-title">Develop</h2>
						<h3>Ideation</h3>
						<p>
							Based on user research and expert interviews, we defined a general
							workflow for a web-based service where users could upload images
							of stained tissue slides.
						</p>

						<ol>
							<li>
								<span>Upload image of tissue slide</span>
							</li>
							<li>
								<span>Analyze tissue slide with image analysis algorithm </span>
							</li>

							<li>
								<span>Receive results</span>
							</li>
						</ol>

						<p>
							If we could automate the analysis, the user would only have to
							upload and later review the results. We were unsure if the
							platform should center around the upload or receiving results. A
							platform centered around uploading could ensure the completion of
							the first step of the workflow but a platform centered around
							receiving results gives the user the information he/she wanted in
							the first place.
						</p>
						<p>
							I sketched up six concepts with distinct elements and tested each
							to see which one resonated best with the user segment:
						</p>
					</div>
					<div className="image-grid">
						<div className="article-img3">
							<Zoom>
								<img alt="Concept 1" src={img1} width="100%" />
							</Zoom>

							<Zoom>
								<img alt="Concept 2" src={img2} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Concept 3" src={img3} width="100%" />
							</Zoom>

							<Zoom>
								<img alt="Concept 4" src={img4} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Concept 5" src={img5} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Concept 6" src={img6} width="100%" />
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>
							Different concepts for the platform dashboard - The starting point
							for all interaction.
						</p>
					</div>

					<div className="content-details">
						<p>
							Usability testing indicated that users gained the most value from
							having the results at the core of the platform. The team also
							preferred this solution since uploading images could be automated
							later, thereby only requiring the users to access the platform
							when they wished to review results.
						</p>

						<h3>Iterations</h3>
						<div className="light">
							<p>Dashboard</p>
						</div>
						<p>
							To automate the image analysis step, metadata from the images must
							be provided somehow. Without integration into the lab information
							systems, we would have no choice but to get it from the user. We
							chose to divide the results from the image analysis into
							categories and then use these categories when users were uploading
							to the platform. Thereby we could gain the necessary information
							about the images without actively requiring it from the users.
						</p>
						<p>
							This added an extra step to the workflow. However, to avoid adding
							an extra click we chose to implement an upload for each category.
							This meant that we had many different upload buttons on the
							dashboard. To avoid cluttering, we hid the upload buttons under a
							hover function. We also used the category box as a button for
							accessing detailed information about the specific category.
						</p>
					</div>

					<div className="article-img">
						<Zoom>
							<img alt="Hidden Buttons" src={img7} width="100%" />
						</Zoom>
						<p>
							Each box represents a category that users can upload images to.
							When hovering the user would be presented with an upload button to
							remove clutter from the dashboard. The user can also click on the
							category box to view detailed information about the category.
						</p>
					</div>

					<div className="content-details">
						<p>
							Through usability testing, we realized that a simplified interface
							did not perform as we had hoped. Instead we chose to display the
							buttons permanently.
						</p>
					</div>
					<div className="article-img">
						<Zoom>
							<img alt="Dashboard" src={img8} width="100%" />
						</Zoom>
						<p>Dashboard of the platform</p>
					</div>
					<div className="content-details">
						<div className="light">
							<p>Plots</p>
						</div>
						<p>
							The results are the most important element on the platform since
							they show the quality performance of all uploaded tissue slides
							over time. This gives the user an indication of consistency. The
							team was unsure which type of plot would be most valuable for the
							user so I created a prototype where the plots could be customized.
						</p>

						<p>
							I tested the prototype with five users and asked them to customize
							the plots to match their preferences.
						</p>
					</div>
					<div className="article-img">
						<Zoom>
							<img alt="Plot configurator" src={img9} width="100%" />
						</Zoom>
						<p>Prototype where plots are fully customizable</p>
					</div>

					<div className="content-details">
						<p>
							By making this prototype we learned that users preferred a certain
							plot type. By avoiding the task of making several plot types
							available on the platform we saved a lot of development time.
						</p>
					</div>

					<div className="content-details">
						<h2 className="content-title">Deliver</h2>

						<p>What the project delivered:</p>
						<ul>
							<li>
								<span>
									Increased sales – The solution opens up a new customer segment{" "}
								</span>
							</li>
							<li>
								<span>
									A step closer to standardization – Several users found issues
									with their production and were able to act on it because of
									the platform.
								</span>
							</li>

							<li>
								<span>
									Blue ocean - No other company has a similar solution.
								</span>
							</li>
							<li>
								<span>
									A new way of using components - The web platform is connected
									to our existing solutions for image analysis thus providing a
									new way of delivering image analysis to customers.
								</span>
							</li>
						</ul>
					</div>
					<div className="content-details">
						<h2 className="content-title">Key Learnings</h2>

						<p>
							Design sprints accelerate learning - During the project, our team
							had several design sprints to accelerate learning. For each
							sprint, the team managed to accomplish a month’s work in a matter
							of days.
						</p>

						<p>
							Pilot sites are golden - During the project, our team had access
							to three pilot sites and a partner for testing and interviewing.
							This drastically decreased the amount of time otherwise used on
							finding users to test on.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default QualityAssessment;
