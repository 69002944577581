import React from "react";
import "./ShortDescriptionStyles.css";
import { FaWindows, FaChrome } from "react-icons/fa";

class ShortDescriptionFM extends React.Component {
	render() {
		return (
			<div className="short">
				<div className="challenge">
					<h4>Challenge:</h4>
					<p>
						How could we improve the experience of importing images in such way
						that it helps users to quickly get started with core functions in
						the platform?
					</p>
				</div>
				<div className="facts">
					<div className="fact">
						<h4>Start:</h4>
						<p>Sep 2019</p>
					</div>
					<div className="fact">
						<h4>End:</h4>
						<p>Jul 2020</p>
					</div>

					<div className="fact">
						<h4>Platform:</h4>
						<div className="icons">
							<FaWindows
								size={20}
								style={{ color: "#fff", display: "visible" }}
							/>
							<FaChrome size={20} style={{ color: "#fff", display: "none" }} />
						</div>
					</div>
					<div className="fact">
						<h4>Tools:</h4>

						<p>Figma, Adobe XD, Adobe Illustrator</p>
					</div>
				</div>
				<div className="challenge">
					<h4>My Team:</h4>
					<p>
						Three full-stack developers, a project/backlog manager, and me as
						the UX designer and product owner/manager.
					</p>
				</div>
				<div className="challenge">
					<h4>My Role:</h4>
					<p>
						I was responsible for setting the overall design direction for the
						project as well as releasing the product internally and externally.{" "}
					</p>
				</div>
			</div>
		);
	}
}
export default ShortDescriptionFM;
