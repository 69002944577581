import React from "react";
import "./ShortDescriptionStyles.css";
import { FaWindows, FaChrome } from "react-icons/fa";

class ShortDescriptionQA extends React.Component {
	render() {
		return (
			<div className="short">
				<div className="challenge">
					<h4>Challenge:</h4>
					<p>
						How might we create a solution to validate quality of
						immunohistochemical (IHC) stains to ensure correct treatment for
						cancer patients through better quality assessment of diagnostic
						biomarkers?
					</p>
				</div>
				<div className="facts">
					<div className="fact">
						<h4>Start:</h4>
						<p>Jan 2021</p>
					</div>
					<div className="fact">
						<h4>End:</h4>
						<p>Mar 2022</p>
					</div>

					<div className="fact">
						<h4>Platform:</h4>
						<div className="icons">
							<FaWindows size={20} style={{ color: "#fff", display: "none" }} />
							<FaChrome
								size={20}
								style={{ color: "#fff", display: "visible" }}
							/>
						</div>
					</div>
					<div className="fact">
						<h4>Tools:</h4>

						<p>Figma, Adobe XD, Adobe Illustrator, VS Code</p>
					</div>
				</div>
				<div className="challenge">
					<h4>My Team:</h4>
					<p>
						Two full-stack developers, a tech lead, a product manager, two image
						analysis specialists and me as the UX designer.
					</p>
				</div>
				<div className="challenge">
					<h4>My Role:</h4>
					<p>
						I was responsible for setting the overall user experience and design
						direction for the project.
					</p>
				</div>
			</div>
		);
	}
}
export default ShortDescriptionQA;
