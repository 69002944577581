import "./HeroTextStyles.css";
import React from "react";
import { Link } from "react-router-dom";

const HeroText = () => {
	return (
		<>
			<div className="fun">
				<h1 className="light">
					<span className="letter">I</span>
					<span className="letter">'</span>

					<span className="letter">m</span>
					<span className="space"> </span>
					<span className="letter">R</span>
					<span className="letter">a</span>
					<span className="letter">s</span>
					<span className="letter">m</span>
					<span className="letter">u</span>
					<span className="letter">s</span>
					<span className="letter">.</span>
				</h1>
				<div className="box">
					<h1 className="big">
						<span className="letter">A</span>
						<span className="space"> </span>

						<span className="letter">D</span>
						<span className="letter">e</span>
						<span className="letter">s</span>
						<span className="letter">i</span>
						<span className="letter">g</span>
						<span className="letter">n</span>
						<span className="space"> </span>
					</h1>
					<h1 className="big">
						<span className="letter">E</span>
						<span className="letter">n</span>
						<span className="letter">g</span>
						<span className="letter">i</span>
						<span className="letter">n</span>
						<span className="letter">e</span>
						<span className="letter">e</span>
						<span className="letter">r</span>
					</h1>
				</div>
				<div className="box">
					<h1 className="opacity">
						<span className="letter">W</span>
						<span className="letter">o</span>
						<span className="letter">r</span>
						<span className="letter">k</span>
						<span className="letter">i</span>
						<span className="letter">n</span>
						<span className="letter">g</span>
						<span className="space"> </span>
						<span className="letter">W</span>
						<span className="letter">i</span>
						<span className="letter">t</span>
						<span className="letter">h</span>
						<span className="space"> </span>
					</h1>
					<h1 className="opacity">
						<span className="letter">U</span>
						<span className="letter">X</span>
						<span className="letter">/</span>
						<span className="letter">U</span>
						<span className="letter">I</span>
					</h1>
				</div>
			</div>
			<div className="fun">
				<p className="text">
					I’m an enthusiastic and skilled UX/UI Designer with more than 3 years
					of hands-on experience conceptualizing and designing market-leading AI
					solutions. I have created several products used by doctors and
					scientist world-wide which enable them to efficiently solve the
					growing challenges of the new era of cancer healthcare.
				</p>
			</div>
			<div className="fun">
				<div className="buttons">
					<Link to="/projects" className="btn">
						Go To Portfolio
					</Link>
				</div>
			</div>
		</>
	);
};

export default HeroText;
