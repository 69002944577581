import React from "react";
import "./ShortDescriptionStyles.css";
import { FaWindows, FaChrome } from "react-icons/fa";

class ShortDescriptionCB extends React.Component {
	render() {
		return (
			<div className="short">
				<div className="challenge">
					<h4>Challenge:</h4>
					<p>
						How might we create a zero-coding solution for scientists to gain
						single-cell insights into the intricate spatial relationships of
						cells, pathways, and phenotypes in the tissue microenvironment.
					</p>
				</div>
				<div className="facts">
					<div className="fact">
						<h4>Start:</h4>
						<p>Jan 2021</p>
					</div>
					<div className="fact">
						<h4>End:</h4>
						<p>Oct 2022</p>
					</div>

					<div className="fact">
						<h4>Platform:</h4>
						<div className="icons">
							<FaWindows
								size={20}
								style={{ color: "#fff", display: "visible" }}
							/>
							<FaChrome size={20} style={{ color: "#fff", display: "none" }} />
						</div>
					</div>
					<div className="fact">
						<h4>Tools:</h4>

						<p>Figma, Adobe XD, Illustrator, Miro</p>
					</div>
				</div>
				<div className="challenge">
					<h4>My Team:</h4>
					<p>
						Three full-stack developers, a tech lead, a product manager, and me
						as the UX designer.
					</p>
				</div>
				<div className="challenge">
					<h4>My Role:</h4>
					<p>
						I was responsible for setting the overall design direction for the
						project.
					</p>
				</div>
			</div>
		);
	}
}
export default ShortDescriptionCB;
