import "./NavbarStyles.css";

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);

	const [color, setColor] = useState(false);
	const changeColor = () => {
		if (window.scrollY >= 50) {
			setColor(true);
		} else {
			setColor(false);
		}
	};

	window.addEventListener("scroll", changeColor);

	return (
		<div className={color ? "header header-bg" : "header"}>
			<Link to="/">
				<div className="logo">
					<h1>RNS</h1>
				</div>
				{/*
				<div className="slide-right">
					<p>Back to home</p>
	</div>*/}
			</Link>
			<ul className={click ? "nav-menu active" : "nav-menu"}>
				<li>
					<Link to="/Projects">Projects</Link>
				</li>
				{/*<li>
					<Link to="/Method">My Method</Link>
</li>*/}
				<li>
					<Link to="/profile">Account</Link>
				</li>
			</ul>

			<div className="hamburger" onClick={handleClick}>
				{click ? (
					<FaTimes size={25} style={{ color: "#fff" }} />
				) : (
					<FaBars size={25} style={{ color: "#fff" }} />
				)}
			</div>
		</div>
	);
};

export default Navbar;
