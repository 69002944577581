import pro1 from "../assets/FileManager/OnlyDatabase.png";
import pro2 from "../assets/ComplexBiology/OnlyMultiplex.png";
import pro3 from "../assets/Quality/OnlyQuality.png";

const ProjectCardData = [
	{
		imgsrc: pro1,
		title: "Managing files in a IA platform",
		text: "Managing files for creating robust image analysis (IA) algorithms",
		view: "/filemanager",
	},

	{
		imgsrc: pro2,
		title: "Understanding complex spatial biology",
		text: "Augment the visualisation of the complex spatial biology of the tissue microenvironment.",
		view: "/complexbiology",
	},

	{
		imgsrc: pro3,
		title: "Quality assessment of diagnostic markers",
		text: "Provide correct treatment for cancer patients through better quality assessment of diagnostic biomarkers",
		view: "/qualityassessment",
	},
];

export default ProjectCardData;
