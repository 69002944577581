import React from "react";

import HeroImg2 from "../components/HeroImg2";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import Work from "../components/Work";

const Projects = () => {
	return (
		<>
			<Navbar />
			<HeroImg2
				heading="Projects"
				text="Interesting projects I have worked on."
				subtext="I have included some examples to show the process from ideation to final solution."
			/>
			<Work />

			<Footer />
		</>
	);
};

export default Projects;
