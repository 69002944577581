import "./FormStyles.css";
import { useAuthValue } from "./AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";

function Profile() {
	const { currentUser } = useAuthValue();

	return (
		<div className="center">
			<div className="auth">
				<div className="profile">
					<h1>Hello!</h1>
					{/*<p>
						<strong>Email: </strong>
						{currentUser?.email}
					</p>
					<p>
						<strong>Email verified: </strong>
						{`${currentUser?.emailVerified}`}
	</p>*/}
					<button onClick={() => signOut(auth)} className="btn">
						Sign Out
					</button>
				</div>
			</div>
		</div>
	);
}

export default Profile;
