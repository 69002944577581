import React from "react";
import "../components/ArticleStyles.css";

import Navbar from "../components/Navbar";
import img0 from "../assets/FileManager/OnlyDatabase.png";
import img1 from "../assets/FileManager/User Journeys.png";
import img2 from "../assets/FileManager/Creating a database.png";
import img3 from "../assets/FileManager/OriginalDatabase.png";
import img4 from "../assets/FileManager/Import screen.png";
import img5 from "../assets/FileManager/Home screen.png";
import img6 from "../assets/FileManager/Searching.png";
import img7 from "../assets/FileManager/Sus task.png";
import img8 from "../assets/FileManager/Sus score.png";
import img9 from "../assets/FileManager/w-analysis.png";
import img10 from "../assets/FileManager/wo-analysis.png";
import img11 from "../assets/FileManager/user1.png";

import img13 from "../assets/FileManager/user3.png";

import img15 from "../assets/FileManager/wthumbnailsearch.png";
import img16 from "../assets/FileManager/bottomsearch.png";

import { AiFillWindows } from "react-icons/ai";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ShortDescriptionFM from "../components/ShortDescriptionFM";
import Footer from "../components/Footer";

const FileManager = () => {
	return (
		<>
			<Navbar />

			<div className="article-container">
				<div className="content-container">
					<h1>Managing files in a IA platform</h1>
					<p>
						Managing files for creating robust image analysis (IA) algorithms
					</p>
					<div className="article-img">
						<img alt="" src={img0} width="100%" />
					</div>
					<ShortDescriptionFM />

					<div className="content-details">
						<h2 className="content-title">UX Activities</h2>

						<ul>
							<li>
								<span>Analyzing existing solution</span>
							</li>
							<li>
								<span>
									Interviewing internal stakeholders to map expectations{" "}
								</span>
							</li>
							<li>
								<span>Screening users for interviews and testing</span>
							</li>
							<li>
								<span>
									Interviewing users from different user segments located in the
									EU and US.
								</span>
							</li>
							<li>
								<span>
									Testing and co-creating prototypes/workflows with users{" "}
								</span>
							</li>
							<li>
								<span>Designing UI</span>
							</li>
							<li>
								<span>Usability testing of the final solution</span>
							</li>
							<li>
								<span>Internal training for sales and service</span>
							</li>
							<li>
								<span>Preparing for market release with marketing</span>
							</li>
							<li>
								<span>Pricing of the product and associated services</span>
							</li>
						</ul>
					</div>
					<div className="content-details">
						<h2 className="content-title">Discovery</h2>
						<p>
							To get started with any type of image analysis (IA), the user must
							choose the images they want to analyze. The existing file
							management component, which handled the images, had not received
							any major updates for almost 10 years. The developers were scared
							of touching the code in case it would break.
						</p>
					</div>
					<div className="article-img">
						<Zoom>
							<img alt="Old interface" src={img3} width="100%" />
						</Zoom>

						<p>Old interface that needed replacement</p>
					</div>

					<div className="content-details">
						<p>
							Together with the management and the project manager, we planned
							three months of discovery work before the developers would start
							coding. As the first step, I wanted to understand the product
							footprint and the associated workflows.
						</p>
						<p>
							I sat down with several internal stakeholders to understand the
							intended workflows for the product. I searched through the
							request/registration database to understand the frequency and
							extent of the problems encountered by the users. With this
							information, I was able to do a heuristic evaluation of the
							existing file manager and link this to issues reported by users.
						</p>
					</div>

					<div className="article-img">
						<Zoom>
							<img
								alt="Heuristic evaluation of the import workflow - A workflow all users go through"
								src={img2}
								width="100%"
							/>
						</Zoom>

						<p>
							Heuristic evaluation of the image import workflow - This was just
							one of many heuristic analyses.
						</p>
					</div>
					<div className="content-details">
						<p>
							Searching through our bug and request database, it became evident
							that this component added quite a lot of friction to our most
							common workflows. It was also clear that we could not just
							implement the requests one-to-one since these were all based on
							how to improve the current component and we definitely did not
							want to optimize a suboptimal product now that we had the chance
							to start over and create a strong foundation to build on for the
							next many years.
						</p>
						<p>
							With this information and the mindset of making a radical update,
							the team and I defined a (broad) hypothesis to guide the following
							discovery work.
						</p>
					</div>
					<div className="quote">
						<p>
							The existing solution limits the capability and efficiency of the
							user affecting his/her ability to use the core functions because
							of: the importing workflow, the rigid file structure, and the
							limited options for sharing.
						</p>
					</div>
					<div className="content-details">
						<p>
							To better understand the use cases, I needed to interview our
							users, so I created a list together with internal stakeholders of
							users from different segments and from different countries. I
							reached out to all on the list hoping to set up as many interviews
							as possible. The plan was to spread out the interviews over most
							of the project period to constantly have someone to get feedback
							from or to test prototypes or alpha builds with.
						</p>
						<p>
							From the interview, we derived several breakdowns where users
							either had created workarounds, developed complementary software,
							or chosen to switch to competitors to overcome the problems.{" "}
						</p>
					</div>
					<div className="article-img">
						<Zoom>
							<img
								alt="USER JOURNEYS FOR CURRENT FILE MANAGEMENT - Sequence Model"
								src={img1}
								width="100%"
							/>
						</Zoom>
						<p>
							Some of the user journeys/sequences for the existing file manager
						</p>
					</div>

					<div className="content-details">
						<h2 className="content-title">Define</h2>
						<p>
							To make my findings more manageable for the team, I created
							personas focusing on the differences between all the interviewed
							users and their use cases.
						</p>
					</div>

					<div className="image-grid">
						<div className="article-img2">
							<Zoom>
								<img alt="Persona1" src={img11} width="100%" />
							</Zoom>

							<Zoom>
								<img alt="Persona2" src={img13} width="100%" />
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>Personas used by the team during ideation sessions.</p>
					</div>

					<div className="content-details">
						<p>
							I discussed the findings with the team and during several
							sessions, we managed to create an extensive User Story Map
							(filling up an entire office) to understand which use cases to
							solve first and how they could be improved over time.
						</p>
						<p>
							I used the map to sketch low-fidelity wireframes. These were great
							conversation starters both in the team and with the users.
						</p>
					</div>

					<div className="image-grid">
						<div className="article-img3">
							<Zoom>
								<img alt="Importing Images Wireframe" src={img4} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="File Manager Overview" src={img5} width="100%" />
							</Zoom>
							<Zoom>
								<img
									alt="Searching for Images wireframe"
									src={img6}
									width="100%"
								/>
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>Low-fidelity wireframes materialize the ideas from the team. </p>
					</div>

					<div className="content-details">
						<h2 className="content-title">Development</h2>
						<p>
							From the low-fidelity wireframes, we detailed the User Story Map
							even more. This allowed me to create high-fidelity mockups and
							test them with users to further fine-tune the mockups all while
							keeping the developers in the loop to ensure that the wireframes
							reflected something that could actually be developed within the
							timeframe.
						</p>

						<p>
							Once the developers had created an alpha version where the user
							could get from start to finish for the most common tasks, I
							conducted five usability tests with users from different
							companies. After each test, I asked users to fill out a survey to
							track satisfaction with the new solution. I also recorded
							everything to accurately monitor task completion and errors.
						</p>
					</div>

					<div className="image-grid">
						<div className="article-img2">
							<Zoom>
								<img
									alt="Task-level satisfaction scores"
									src={img7}
									width="100%"
								/>
							</Zoom>
							<Zoom>
								<img
									alt="Test-level satisfaction score"
									src={img8}
									width="100%"
								/>
							</Zoom>
						</div>
					</div>

					<div className="article-img">
						<p>Analysis of task-level and test-level satisfaction</p>
					</div>
					<div className="content-details">
						<p>
							Here is an example of how we learned from testing with users. The
							team and I wished to make importing images as easy as possible so
							we went for a one-click solution. From user testing, we learned
							that the one-click concept was great the first time around but
							users quickly wanted to organize their images before clicking
							import. The one-click solution ended up forcing users to import
							and then organize even though they wanted to organize first. So we
							opted for a solution where the user immediately had to organize
							the images.
						</p>
						<h2 className="content-title">Deliver</h2>
						<p>
							I reported my findings to the team and we fine-tuned some of the
							features and further prioritized the development backlog. We
							managed to create a product that makes the users more efficient
							(50% increase) through constant prioritization and ongoing
							discovery work.
						</p>
					</div>
					<div className="image-grid">
						<div className="article-img2">
							<Zoom>
								<img alt="Final interface 1" src={img9} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Final interface 2" src={img10} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Final interface 3" src={img15} width="100%" />
							</Zoom>
							<Zoom>
								<img alt="Final interface 4" src={img16} width="100%" />
							</Zoom>
						</div>
					</div>
					<div className="article-img">
						<p>Final interface for the File Manager</p>
					</div>
					<div className="content-details">
						<p>
							A few months before finishing the project, I reached out to
							internal stakeholders to establish a marketing plan and pricing
							for the product and associated services. I also presented the
							updates to several key prospects to secure more sales.
						</p>
						<h2 className="content-title">Key Learnings</h2>
						<p>
							It is a very complex task to replace components, especially if
							these components are as close to the core functions as this one.
							You must constantly be aware of how the existing solution is
							affecting users' opinions making their feedback biased. Users want
							something better, even when they do not yet know it, so do not
							implement one-to-one what they told you.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default FileManager;
