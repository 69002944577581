import "./HeroImg2Styles.css";
import React from "react";

class HeroImg2 extends React.Component {
	render() {
		return (
			<div className="hero-img">
				<div className="heading">
					<h1>{this.props.heading}</h1>
					<p>{this.props.text}</p>
					<p className="small">{this.props.subtext}</p>
				</div>
			</div>
		);
	}
}

export default HeroImg2;
