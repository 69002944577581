import "./HeroImgStyles.css";
import React from "react";

import HeroText from "./HeroText";

const HeroImg = () => {
	return (
		<div className="hero">
			<div className="mask">
				{/*<img className="intro-img" src={IntroImg} alt="" />*/}
			</div>
			<div className="content">
				{/*	<p>HI, MY NAME IS RASMUS</p>
				<h1>Welcome to my portfolio</h1>*/}
				<HeroText />
			</div>
		</div>
	);
};

export default HeroImg;
